@import "variable";
@import "fonts";

@import "structure";
@import "typography";
@import "helpers";
@import "material-components";
@import 'ngx-toastr/toastr';


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.subsection-label {
  font-size: 1.5em;
}

.form-subsection {
  border: solid lightgrey;
  border-radius: 10px;
}

.with-tooltip:hover .helper-text {
  display: block;
  transition-delay: 0.5s;
}

.helper-text {
  display: none;
  z-index: 100;
  background-color: white;
  transition-property: display;
}
